<template>
  <div>
    <BottomBodyContainer v-if="widgetsInBottom.length != 0" />
    <footer id="footer">
      <div>
        <ul>
          <li class="l-mediaBlock__content">
            <div class="l-mediaBlock__titleBlock">
              <h2 class="l-mediaBlock__title">Popular Category</h2>
              <span class="l-mediaBlock__sub">人気カテゴリー</span>
            </div>
            <div class="p-spWidgetTagBox">
              <a
                v-for="(category, index) in footerCategories"
                :key="index"
                :href="category.url"
                class="p-spWidgetTag__tag"
                rel="noopener noreferrer"
                >{{ category.name }}</a
              >
            </div>
            <div class="p-spWidget__btnBlock__wrap">
              <p class="p-spWidget__btnBlock">
                <a class="p-spWidget__btn" href="/categories" data-wpel-link="internal"
                  ><i class="fas fa-arrow-circle-right mr-2"></i>カテゴリー一覧</a
                >
              </p>
            </div>
          </li>
          <li class="l-mediaBlock__content">
            <div class="l-mediaBlock__titleBlock">
              <h2 class="l-mediaBlock__title">Popular Brand</h2>
              <span class="l-spMediaBlock__sub">人気ブランド</span>
            </div>
            <div class="p-spWidgetTagBox">
              <a
                v-for="(brand, index) in footerBrands"
                :key="index"
                :href="brand.url"
                class="p-spWidgetTag__tag"
                rel="noopener noreferrer"
                >{{ brand.name }}</a
              >
            </div>
            <div class="p-spWidget__btnBlock__wrap">
              <p class="p-spWidget__btnBlock">
                <a class="p-spWidget__btn" href="/brands" data-wpel-link="internal"
                  ><i class="fas fa-arrow-circle-right mr-2"></i>ブランド一覧</a
                >
              </p>
            </div>
          </li>
        </ul>
        <div class="p-toTopBlock" @click="this.toTop">
          <i class="fa fa-chevron-up"></i>
          <p>ページ上部に戻る</p>
        </div>
      </div>
      <div class="l-footer__info">
        <ul class="cf">
          <li v-for="(page, index) in footerPages" :key="index">
            <a v-if="page.url" :href="page.url" target="_blank" data-wpel-link="external" rel="noopener noreferrer"
              ><i class="fas fa-chevron-circle-right"></i> {{ page.title }}
            </a>
            <a v-else :href="page.slug" data-wpel-link="internal"
              ><i class="fas fa-chevron-circle-right"></i> {{ page.title }}
            </a>
          </li>
        </ul>
      </div>
      <p class="l-footer__copyRight">&copy; mattrz All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BottomBodyContainer from '../container/widgets/BottomBodyContainer'

export default {
  name: 'MediaFooter',
  components: { BottomBodyContainer },
  computed: {
    ...mapState({
      footerCategories: (state) => state.tag.footerCategories,
      footerBrands: (state) => state.tag.footerBrands,
      footerPages: (state) => state.tag.footerPages,
      widgetsInBottom: (state) => state.top.widgetsInBottom,
    }),
  },
  methods: {
    toTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    onClickGaAction(args) {
      window.ga(...args)
    },
  },
}
</script>
