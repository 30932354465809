import Layout from '../Layout'
import NotFound from '../../NotFound'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Top from './Top'
import MediaHeader from '../common/MediaHeader'
import MediaFooter from '../common/MediaFooter'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        components: { default: Top, header: MediaHeader, footer: MediaFooter },
      },
    ],
  },
  {
    path: '/*',
    name: 'notfound',
    component: NotFound,
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
