<template>
  <div>
    <InHeadContainer v-if="widgetsInHead.length != 0" />
    <header id="header" class="scroll_fix l-header">
      <div class="header_wrapper">
        <div>
          <p class="l-header__logo">
            <a href="/" class="l-header__logoImg">
              <img :src="require('../../../images/folk_logo.png')" alt="Logo" />
            </a>
          </p>
        </div>
        <!-- <h1 class="l-header__subTitle">大人女性向けライフスタイルメディア</h1> -->
        <div class="l-header__socialBtnWrapper cf">
          <HeaderLogin />
          <HeaderSocialBtns />
        </div>
        <!-- <HeaderCategoryTab /> -->

        <div id="l-header__searchBtn" @click="toggleSearchContainerDisplay">
          <i class="far fa-search" aria-hidden="true"></i>
        </div>
      </div>
      <!--検索-->
      <HeaderSearchContainer />

      <!--スマホナビ-->
      <HeaderNavSidebar />
    </header>
  </div>
</template>

<script>
import HeaderSocialBtns from './HeaderSocialBtns'
import HeaderLogin from './HeaderLogin'
// import HeaderCategoryTab from './HeaderCategoryTab'
import HeaderSearchContainer from './HeaderSearchContainer'
import HeaderNavSidebar from './HeaderNavSidebar'
import InHeadContainer from '../container/widgets/InHeadContainer'
import { mapState } from 'vuex'

export default {
  name: 'MediaHeader',
  components: {
    HeaderSocialBtns,
    HeaderLogin,
    // HeaderCategoryTab,
    HeaderSearchContainer,
    HeaderNavSidebar,
    InHeadContainer,
  },
  data: function () {
    return {
      on: false,
    }
  },
  computed: {
    ...mapState({
      widgetsInHead: (state) => state.top.widgetsInHead,
    }),
  },
  methods: {
    toggleSearchContainerDisplay() {
      let searchBtn = document.getElementById('l-header__searchBtn')
      let searchContainer = document.getElementById('l-searchContainer')
      searchBtn.classList.toggle('on')
      searchContainer.classList.toggle('on')
    },
  },
}
</script>
