<template>
  <MediaContentNoBgBlock>
    <MediaTitleBlock>
      <SectionHeadLabel :label="'Professional'" />
      <SectionHeadSubLabel :label="'専門家'" />
    </MediaTitleBlock>

    <PartnershipsContainer />
    <div class="l-mediaBlock__buttonBlock__wrap">
      <LinkButton :label="'専門家一覧'" :url="professionalAuthorsUrl" />
    </div>
  </MediaContentNoBgBlock>
</template>

<script>
import SectionHeadSubLabel from '../../uiParts/labels/SectionHeadSubLabel'
import SectionHeadLabel from '../../uiParts/labels/SectionHeadLabel'
import MediaTitleBlock from '../../uiParts/blocks/MediaTitleBlock'
import MediaContentNoBgBlock from '../../uiParts/blocks/MediaContentNoBgBlock'
import PartnershipsContainer from '../../container/Top/PartnershipsContainer'
import LinkButton from '../../uiParts/buttons/LinkButton'

export default {
  components: {
    PartnershipsContainer,
    MediaContentNoBgBlock,
    MediaTitleBlock,
    SectionHeadLabel,
    SectionHeadSubLabel,
    LinkButton,
  },
  data() {
    return {
      professionalAuthorsUrl: `/specialists`,
    }
  },
}
</script>
