<template>
  <div class="l-inner__withTab">
    <TopPickPanel :post="topPickSpPost" :with-icon="true" v-if="topPickSpPost !== null" />
    <WidgetsTopBody v-if="widgetsTopBody.length != 0" />
    <PickUpPost />
    <ProfessionalAuthors />
    <Features />
    <NewPosts />
    <Partnerships />
  </div>
</template>

<script>
import TopPickPanel from '../shared/TopPickPanel'
import PickUpPost from './PickUpPost'
import ProfessionalAuthors from './ProfessionalAuthors'
import Features from './Features'
import NewPosts from './NewPosts'
import Partnerships from './Partnerships'
import WidgetsTopBody from '../container/widgets/TopBodyContainer'
import { mapState } from 'vuex'
export default {
  components: {
    Partnerships,
    Features,
    NewPosts,
    ProfessionalAuthors,
    PickUpPost,
    TopPickPanel,
    WidgetsTopBody,
  },
  computed: {
    ...mapState({
      topPickSpPost: (state) => state.top.topPickSpPost,
      widgetsTopBody: (state) => state.top.widgetsTopBody,
    }),
  },
}
</script>
