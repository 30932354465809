<template>
  <span class="l-mediaBlock__sub">{{ label }}</span>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
}
</script>
