import snakecaseKeys from 'snakecase-keys'

const VueAxiosPlugin = {}
export default VueAxiosPlugin.install = function (Vue, { axios, csrfToken }) {
  if (!axios) {
    return
  }

  axios.interceptors.request.use((request) => {
    if (typeof csrfToken === 'function') {
      request.headers['X-CSRF-Token'] = csrfToken()
    }
    if (request.data) {
      request.data = snakecaseKeys(request.data)
    }
    return request
  })

  Vue.axios = axios

  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios
      },
    },
  })
}
