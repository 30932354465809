<template>
  <a :id="`pr_${post.data.id}`" class="p-postCard" :href="`/${post.data.id}`" @click="clickItem">
    <div
      class="p-postCard__img"
      :class="{ 'p-postCard__imgCart': post.data.attributes.hasProductPosts }"
      :style="`background: url(${eyecatchUrl()}) no-repeat 0px;background-size: cover;`"
    ></div>
    <div class="p-postCard__content">
      <div class="p-postCard__content__description">
        {{ post.data.attributes.title }}
      </div>
      <a :href="`/authors/${author.slug}`" class="p-postCard__content__author">
        <div class="p-postCard__content__author__content">
          <span>{{ authorJob() }}</span>
          <span>{{ author.name }}</span>
        </div>
        <img :src="avatarUrl()" alt="avatar" class="p-postCard__content__authorImg" />
      </a>
    </div>
  </a>
</template>

<script>
import noImage from '../../../images/no-image.jpg'

export default {
  name: 'pickUpPostCard',
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      author: this.post.included[0].attributes || {},
    }
  },
  methods: {
    clickItem() {
      sessionStorage.setItem('scroll_to', window.top.scrollY)
    },
    eyecatchUrl() {
      return this.post.data.attributes.eyecatchImage.url || noImage
    },
    avatarUrl() {
      if (this.author.avatarImage && this.author.avatarImage.url) return this.author.avatarImage.url

      return noImage
    },
    authorJob() {
      return this.author.job || 'ライター'
    },
  },
}
</script>
