<template>
  <DateLabelIcon :day="dayLabel()" :month="monthLabel()" />
</template>
<script>
import moment from 'moment'
import DateLabelIcon from '../../shared/TopPickPanel/DateLabelIcon'

export default {
  components: { DateLabelIcon },
  methods: {
    dayLabel() {
      const today = moment()
      return today.format('DD')
    },
    monthLabel() {
      const today = moment()
      return today.format('MMM').toUpperCase()
    },
  },
}
</script>
