<template>
  <div class="l-mediaBlock">
    <keep-alive>
      <TopContent />
    </keep-alive>
  </div>
</template>

<script>
import TopContent from './TopContent'

export default {
  name: 'Top',
  components: { TopContent },
  mounted() {
    document.body.classList.add('c-bodySpHomePage')
    this.$store.dispatch('top/fetchLayoutTops')
  },
}
</script>
