<template>
  <v-app>
    <v-col cols="12" sm="8" offset="2" class="mt-12">
      <v-card class="pa-3">
        <v-card-title class="font-weight-bold display-1 text-center"> 404 Page Not Found. </v-card-title>
        <v-card-text> ページが見つかりませんでした。URLを確認してください。 </v-card-text>
        <v-card-actions>
          <v-btn text color="deep-purple accent-4" to="/"> ホームに移動する </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <message />
  </v-app>
</template>
<script>
import Message from '../components/common_parts/Message.vue'

export default {
  name: 'NotFound',
  components: {
    message: Message,
  },
}
</script>
