<template>
  <MediaContentBLock>
    <MediaTitleBlock>
      <SectionHeadLabel :label="'Official Writer'" />
      <SectionHeadSubLabel :label="'公式ライター'" />
    </MediaTitleBlock>
    <ProfessionalAuthorsContainer />
    <div class="l-mediaBlock__buttonBlock__wrap">
      <LinkButton :label="'公式ライター一覧'" :url="professionalAuthorsUrl" />
    </div>
  </MediaContentBLock>
</template>

<script>
import SectionHeadSubLabel from '../uiParts/labels/SectionHeadSubLabel'
import SectionHeadLabel from '../uiParts/labels/SectionHeadLabel'
import MediaTitleBlock from '../uiParts/blocks/MediaTitleBlock'
import MediaContentBLock from '../uiParts/blocks/MediaContentBLock'
import ProfessionalAuthorsContainer from '../container/Top/ProfessionalAuthorsContainer'
import LinkButton from '../uiParts/buttons/LinkButton'

export default {
  components: {
    LinkButton,
    ProfessionalAuthorsContainer,
    MediaContentBLock,
    MediaTitleBlock,
    SectionHeadLabel,
    SectionHeadSubLabel,
  },
  data() {
    return {
      professionalAuthorsUrl: `/authors`,
    }
  },
}
</script>
