<template>
  <a class="p-featureCard" :href="url">
    <div
      class="p-featureCard__img"
      :style="`background: url(${coverUrl}) no-repeat 0px;background-position: center center !important;background-size: contain;background-color: #ooo`"
    ></div>
  </a>
</template>

<script>
export default {
  name: 'FeatureCard',
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    coverUrl: {
      type: String,
      required: true,
    },
  },
}
</script>
