<template>
  <div>
    <router-view name="header" />
    <router-view />
    <router-view name="footer" />
  </div>
</template>
<script>
export default {
  name: 'Layout',
  mounted() {
    this.$store.dispatch('tag/fetchLayoutTags')
  },
}
</script>
