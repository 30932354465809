<template>
  <div ref="inHead" class="u-none">
    <BaseWidgetContainer :widgets="widgetsInHead" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { runWidgetScript } from '../utils/widgetScript'
import BaseWidgetContainer from './BaseWidgetContainer'

export default {
  components: { BaseWidgetContainer },
  computed: {
    ...mapState({
      widgetsInHead: (state) => state.top.widgetsInHead,
    }),
  },
  mounted() {
    this.fetchedAction()
  },
  methods: {
    fetchedAction() {
      runWidgetScript(this.$refs.inHead)
    },
  },
}
</script>
