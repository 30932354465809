<template>
  <div>
    <div v-for="(widget, index) in widgets" :key="index" v-html="widget.attributes.content" />
  </div>
</template>

<script>
export default {
  props: {
    widgets: {
      type: Array,
    },
  },
}
</script>
