<template>
  <PanelListBLock v-if="professionalAuthors.length > 0">
    <PanelItemBlock v-for="(author, index) in professionalAuthors" :key="index">
      <AuthorCard
        :url="authorUrl(author.attributes)"
        :name="author.attributes.name"
        :catchphrase="author.attributes.catchphrase"
        :slug="author.attributes.slug"
        :job="authorJob(author.attributes.job)"
        :cover-url="coverUrl(author.attributes.cover)"
        :avatar-url="avatarUrl(author.attributes)"
      ></AuthorCard>
    </PanelItemBlock>
  </PanelListBLock>
</template>

<script>
import { mapState } from 'vuex'
import PanelListBLock from '../../uiParts/blocks/PanelListBLock'
import PanelItemBlock from '../../uiParts/blocks/PanelItemBlock'
import AuthorCard from '../../shared/AuthorCard/index'
import noImage from '../../../../../assets/images/no_images/no-image.jpg'

export default {
  components: {
    PanelListBLock,
    PanelItemBlock,
    AuthorCard,
  },
  computed: {
    ...mapState({
      professionalAuthors: (state) => state.top.professionalAuthors,
    }),
  },
  methods: {
    coverUrl(cover) {
      return cover.url ? cover.url : noImage
    },
    authorJob(job) {
      return job || ''
    },
    authorUrl(author) {
      return `authors/${author.slug}`
    },
    avatarUrl(author) {
      if (author.avatarImage && author.avatarImage.url) {
        return author.avatarImage.url
      }
      return author.avatar ? author.avatar['150'] : noImage
    },
  },
}
</script>
