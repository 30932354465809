<template>
  <a
    :id="`pr_${product.data.id}`"
    class="p-postCard"
    :href="`${product.data.attributes.shopifyLink}`"
    @click="clickItem"
  >
    <div
      class="p-postCard__img p-postCard__imgCart c-on"
      :style="`background: url(${eyecatchUrl()}) no-repeat 0px;background-size: cover;`"
    ></div>
    <div class="p-postCard__content">
      <div class="p-postCard__content__description">
        {{ product.data.attributes.title }}
      </div>
    </div>
  </a>
</template>

<script>
import noImage from '../../../images/no-image.jpg'

export default {
  name: 'pickUpProductCard',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clickItem() {
      sessionStorage.setItem('scroll_to', window.top.scrollY)
    },
    eyecatchUrl() {
      return this.product.included[0].attributes.fileUrl || noImage
    },
  },
}
</script>
