<template>
  <PanelListBLock v-if="pickSpPosts.length > 0">
    <PanelItemBlock v-for="(pickUp, index) in pickSpPosts" :key="index">
      <PickUpPostCard :post="pickUp" v-if="pickUp.data.type == 'post'" />
      <PickUpProductCard :product="pickUp" v-if="pickUp.data.type == 'product'" />
    </PanelItemBlock>
  </PanelListBLock>
</template>

<script>
import { mapState } from 'vuex'
import PanelListBLock from '../../uiParts/blocks/PanelListBLock'
import PanelItemBlock from '../../uiParts/blocks/PanelItemBlock'
import noImage from '../../../../../assets/images/no_images/no-image.jpg'
import PickUpPostCard from '../../posts/pickUpPostCard'
import PickUpProductCard from '../../product/pickUpProductCard'

export default {
  components: {
    PanelListBLock,
    PanelItemBlock,
    PickUpPostCard,
    PickUpProductCard,
  },
  computed: {
    ...mapState({
      pickSpPosts: (state) => state.top.pickSpPosts,
    }),
  },
  methods: {
    postUrl(postId) {
      return `/${postId}`
    },
    eyecatchUrl(post) {
      return post.eyecatchImage.url || noImage
    },
  },
}
</script>
