import Vue from 'vue'
import axios from 'axios'
import VueAxiosPlugin from '../plugins/media/vue-axios'
import router from '../components/media/pages/routes'
import App from '../components/App'
import { csrfToken } from 'rails-ujs'
import store from '../stores'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import '../stylesheets/folk.scss'
import 'bootstrap/scss/bootstrap-grid'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(VueAxiosPlugin, { axios: axios, csrfToken: csrfToken })
Vue.use(Vue2TouchEvents, { swipeTolerance: 200 })

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#sp_app',
    store,
    router,
    render: (h) => h(App),
  })
})
