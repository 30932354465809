<template>
  <div ref="topBody" class="u-none">
    <BaseWidgetContainer :widgets="widgetsTopBody" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { runWidgetScript } from '../utils/widgetScript'
import BaseWidgetContainer from './BaseWidgetContainer'

export default {
  components: { BaseWidgetContainer },
  computed: {
    ...mapState({
      widgetsTopBody: (state) => state.top.widgetsTopBody,
    }),
  },
  mounted() {
    this.fetchedAction()
  },
  methods: {
    fetchedAction() {
      runWidgetScript(this.$refs.topBody)
    },
  },
}
</script>
