<template>
  <div id="l-searchContainer" class="l-searchContainer">
    <div class="l-searchContainer__tab">
      <button
        class="l-searchContainer__tab__links"
        :class="{ active: isOpenTab('read_article') }"
        type="button"
        @click.prevent="openTabSearch('read_article')"
      >
        記事を読む
      </button>
      <button
        class="l-searchContainer__tab__links"
        :class="{ active: isOpenTab('buy_goods') }"
        type="button"
        @click.prevent="openTabSearch('buy_goods')"
      >
        商品を買う
      </button>
    </div>
    <div id="read_article" class="l-searchContainer__content" :class="{ show: isOpenTab('read_article') }">
      <form method="get" action="https://folk-media.com/search" class="l-searchContainer__form search-form">
        <input type="hidden" name="t" value="article" />
        <input type="text" name="q" id="s_form" value="" placeholder="気になるワードを入力" />
        <input type="submit" value="検索" class="search_btn_text hover_effect" />
      </form>
      <div class="l-searchContainer__recommendSection">
        <p class="l-searchContainer__recommendSectionTitle">トレンドワード</p>
        <ul class="l-searchContainer__tagList">
          <li v-for="(tag, index) in trendTagsPosts" :key="index">
            <a :href="tag.url">{{ tag.name }}</a>
          </li>
        </ul>
        <p class="l-searchContainer__recommendSectionTitle">人気カテゴリー</p>
        <ul class="l-searchContainer__recommendList">
          <li v-for="(category, index) in popularCategoriesPosts" :key="index">
            <a :href="category.url">
              <div
                class="l-searchContainer__recommendThumb"
                :style="{ background: `url(${category.image_url}) no-repeat -35px -20px`, backgroundSize: '150%' }"
              ></div>
              <p class="l-searchContainer__recommendTitle">{{ category.name }}</p>
            </a>
          </li>
        </ul>
        <p class="l-searchContainer__recommendSectionTitle">人気ブランド</p>
        <ul class="l-searchContainer__recommendList">
          <li v-for="(brand, index) in popularBrandsPosts" :key="index">
            <a :href="brand.url">
              <div
                class="l-searchContainer__recommendThumb"
                :style="{ background: `url(${brand.image_url}) no-repeat -35px -20px`, backgroundSize: '150%' }"
              ></div>
              <p class="l-searchContainer__recommendTitle">{{ brand.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div id="buy_goods" class="l-searchContainer__content" :class="{ show: isOpenTab('buy_goods') }">
      <form method="get" action="https://folk-media.com/search" class="l-searchContainer__form search-form">
        <input type="hidden" name="t" value="article" />
        <input type="text" name="q" id="s_form" value="" placeholder="気になるワードを入力" />
        <input type="submit" value="検索" class="search_btn_text hover_effect" />
      </form>
      <div class="l-searchContainer__recommendSection">
        <p class="l-searchContainer__recommendSectionTitle">トレンドワード</p>
        <ul class="l-searchContainer__tagList">
          <li v-for="(tag, index) in trendTagsProducts" :key="index">
            <a :href="tag.url">{{ tag.name }}</a>
          </li>
        </ul>
        <p class="l-searchContainer__recommendSectionTitle">人気カテゴリー</p>
        <ul class="l-searchContainer__recommendList">
          <li v-for="(category, index) in popularCategoriesProducts" :key="index">
            <a :href="category.url">
              <div
                class="l-searchContainer__recommendThumb"
                :style="{ background: `url(${category.image_url}) no-repeat -35px -20px`, backgroundSize: '150%' }"
              ></div>
              <p class="l-searchContainer__recommendTitle">{{ category.name }}</p>
            </a>
          </li>
        </ul>
        <p class="l-searchContainer__recommendSectionTitle">人気ブランド</p>
        <ul class="l-searchContainer__recommendList">
          <li v-for="(brand, index) in popularBrandsProducts" :key="index">
            <a :href="brand.url">
              <div
                class="l-searchContainer__recommendThumb"
                :style="{ background: `url(${brand.image_url}) no-repeat -35px -20px`, backgroundSize: '150%' }"
              ></div>
              <p class="l-searchContainer__recommendTitle">{{ brand.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HeaderSearchContainer',
  props: {
    on: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      trendTagsPosts: (state) => state.tag.trendTagsPosts,
      popularCategoriesPosts: (state) => state.tag.popularCategoriesPosts,
      popularBrandsPosts: (state) => state.tag.popularBrandsPosts,
      trendTagsProducts: (state) => state.tag.trendTagsProducts,
      popularCategoriesProducts: (state) => state.tag.popularCategoriesProducts,
      popularBrandsProducts: (state) => state.tag.popularBrandsProducts,
    }),
  },
  data() {
    return {
      activeTab: 'read_article',
    }
  },
  methods: {
    isOpenTab(tab_id) {
      return this.activeTab === tab_id
    },
    openTabSearch(tab_id) {
      this.activeTab = tab_id
    },
  },
}
</script>
