<template>
  <Avatar
    :url="authorUrl(author)"
    :slug="author.slug"
    :name="author.name"
    :job="authorJob()"
    :avatar-url="avatarUrl(author)"
  />
</template>

<script>
import Avatar from '../../shared/PostCard/Avatar'
import noImage from '../../../../../assets/images/no_images/no-image.jpg'

export default {
  components: {
    Avatar,
  },
  props: {
    author: {
      type: Object,
      required: true,
      validator(obj) {
        return 'name' in obj && 'slug' in obj
      },
    },
  },
  methods: {
    authorJob() {
      return this.author.job ? this.author.job : 'ライター'
    },
    authorUrl(author) {
      return `authors/${author.slug}`
    },
    avatarUrl(author) {
      if (author.avatar_image && author.avatar_image.url) {
        return author.avatar_image.url
      }
      return author.avatar ? author.avatar['150'] : noImage
    },
  },
}
</script>
