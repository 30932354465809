<template>
  <div>
    <a class="p-postCard" :href="shopifyLink">
      <div
        class="p-postCard__img p-postCard__imgCart c-on"
        :style="`background: url(${fileUrl}) no-repeat 0px;background-size: cover;`"
      />
      <div class="p-postCard__content">
        <div class="p-postCard__content__description">
          <p>{{ name }}</p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    fileUrl: {
      type: String,
      default: '',
    },
    shopifyLink: {
      type: String,
      required: true,
    },
  },
}
</script>
