<template>
  <div class="p-spPickupPostItem__icon">
    <p class="p-spPickupPostItem__iconDay">
      <span class="p-spPickupPostItem__iconDayLeft">{{ day }}</span>
      <span class="p-spPickupPostItem__iconDayRight">th</span>
    </p>
    <p class="p-spPickupPostItem__iconWeek">
      {{ month }}
    </p>
    <span class="p-spPickupPostItem__iconCenterLine"></span>
  </div>
</template>

<script>
export default {
  props: {
    day: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
  },
}
</script>
