<template>
  <img :src="src" :alt="alt" :title="title" :class="className" />
</template>

<script>
export default {
  name: 'MediaImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
}
</script>
