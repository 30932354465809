<template>
  <div class="p-partnerShipBlock" v-if="partnerShipAuthors.length > 0">
    <div v-for="(author, index) in partnerShipAuthors" :key="index" class="p-partnerShipBlock__item">
      <AvatarIcon
        :id="index"
        :url="authorUrl(author.attributes)"
        :slug="author.attributes.slug"
        :name="author.attributes.name"
        :avatar-url="avatarUrl(author.attributes)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AvatarIcon from '../../pages/Partnerships/AvatarIcon'
import noImage from '../../../../../assets/images/no_images/no-image.jpg'

export default {
  components: { AvatarIcon },
  computed: {
    ...mapState({
      partnerShipAuthors: (state) => state.top.partnerShipAuthors,
    }),
  },
  methods: {
    authorUrl(author) {
      return `authors/${author.slug}`
    },
    avatarUrl(author) {
      if (author.avatarImage && author.avatarImage.url) {
        return author.avatarImage.url
      }
      return author.avatar ? author.avatar['150'] : noImage
    },
  },
}
</script>
