<template>
  <p class="l-mediaBlock__buttonBlock">
    <a class="l-mediaBlock__button" :href="url" data-wpel-link="internal"
      ><i class="fas fa-arrow-circle-right mr-2"></i>{{ label }}</a
    >
  </p>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
}
</script>
