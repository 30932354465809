export const runWidgetScript = (ref) => {
  // widgetのスクリプトを実行するために、新たにタグを作成して実行させる
  const scripts = ref.querySelectorAll('script')
  scripts.forEach((script) => {
    const parentNode = script.parentNode
    let alternativeNode
    if (!script.src || script.src.includes('https://gist.github.com/')) {
      alternativeNode = document.createElement('iframe')
      alternativeNode.style.display = 'none'
      alternativeNode.src = URL.createObjectURL(
        new Blob(['<!DOCTYPE html><title></title>' + script.outerHTML], {
          type: 'text/html',
        })
      )
      alternativeNode.onload = () => {
        alternativeNode.height = alternativeNode.contentDocument.body.scrollHeight + 50
      }
    } else {
      alternativeNode = document.createElement('script')
      alternativeNode.src = script.src
    }
    parentNode.replaceChild(alternativeNode, script)
  })
}
