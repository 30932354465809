<template>
  <a class="p-partnerShipBlock__itemBlock" :href="url">
    <img :src="avatarUrl" :alt="slug" class="p-partnerShipBlock__itemBlock__logo" />
    <div class="p-partnerShipBlock__itemBlock__name">
      {{ name }}
    </div>
  </a>
</template>

<script>
export default {
  props: {
    slug: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    avatarUrl: {
      type: String,
      required: true,
    },
  },
}
</script>
