<template>
  <a class="p-authorCard" :href="url">
    <div
      class="p-authorCard__img"
      :style="`background: url(${coverUrl}) no-repeat 0px;background-position: center center !important;background-size: cover;background-color: #ooo`"
    ></div>
    <div class="p-authorCard__content">
      <div class="p-authorCard__content__head__wrap">
        <div class="p-authorCard__content__head">
          <div class="p-authorCard__content__head__infoBlock">
            <span class="p-authorCard__content__head__name">{{ name }}</span>
            <span class="p-authorCard__content__head__job">{{ job }}</span>
          </div>
          <MediaImage :src="avatarUrl" :class="'p-authorCard__content__head__avatar'" :alt="slug" :title="slug" />
        </div>
      </div>
      <div class="p-authorCard__content__description">
        {{ catchphrase }}
      </div>
    </div>
  </a>
</template>

<script>
import MediaImage from '../../uiParts/images/MediaImage'

export default {
  name: 'AuthorCard',
  components: { MediaImage },
  props: {
    url: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    catchphrase: {
      type: String,
      required: false,
      default: '',
    },
    slug: {
      type: String,
      required: true,
    },
    job: {
      type: String,
      required: true,
    },
    coverUrl: {
      type: String,
      required: true,
    },
    avatarUrl: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped>
.p-authorCard__content__head__infoBlock {
  overflow: hidden;
}
.p-authorCard__content__head__job {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-break: anywhere;
  white-space: unset;
}
</style>
