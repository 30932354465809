<template>
  <PanelListBLock v-if="newPosts.length > 0">
    <PanelItemBlock v-for="(obj, index) in newPosts" :key="index">
      <PostCard
        v-if="isPost(obj)"
        :id="index"
        :url="postUrl(obj.id)"
        :title="obj.title"
        :eyecatch-url="eyecatchUrl(obj)"
        :has-product-posts="obj.hasProductPosts"
        :author="obj.author"
      ></PostCard>
      <ProductCard
        v-else
        :id="index"
        :name="obj.title || obj.name"
        :file-url="fileUrl(obj.productImages)"
        :shopify-link="obj.shopifyLink"
      ></ProductCard>
    </PanelItemBlock>
  </PanelListBLock>
</template>

<script>
import { mapState } from 'vuex'
import PanelListBLock from '../../uiParts/blocks/PanelListBLock'
import PanelItemBlock from '../../uiParts/blocks/PanelItemBlock'
import PostCard from '../../shared/PostCard'
import ProductCard from '../../shared/ProductCard'
import noImage from '../../../../../assets/images/no_images/no-image.jpg'

export default {
  components: {
    PanelListBLock,
    PanelItemBlock,
    PostCard,
    ProductCard,
  },
  computed: {
    ...mapState({
      newPosts: (state) => state.top.newPosts,
    }),
  },
  methods: {
    postUrl(postId) {
      return `/${postId}`
    },
    isPost(postOrProduct) {
      return postOrProduct.published === 1
    },
    eyecatchUrl(post) {
      return post.eyecatchImage && post.eyecatchImage.url ? post.eyecatchImage.url : noImage
    },
    fileUrl(productImages) {
      if (productImages !== undefined && productImages[0] && productImages[0].file && productImages[0].file.url) {
        return productImages[0].file.url
      }
      return noImage
    },
  },
}
</script>
