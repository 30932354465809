<template>
  <nav class="l-navSidebar">
    <div class="l-navSidebar__Container">
      <input type="checkbox" class="l-navSidebar__menuBtn" id="l-navSidebar__menuBtn" />
      <label for="l-navSidebar__menuBtn" class="l-navSidebar__menuToggle">
        <div class="spinner diagonal part-1" />
        <div class="spinner horizontal" />
        <div class="spinner diagonal part-2" />
      </label>
      <div class="l-navSidebar__innerWrapper">
        <div class="l-navSidebar__innerMenuWrapper">
          <div class="l-navSidebar__innerMenu">
            <div class="l-navSidebar__innerMenuContent">
              <div>
                <HeaderLoginMobile :categories="navigationCategories" :brands="menuBrands" />
              </div>
            </div>
          </div>
        </div>
        <div class="l-navSidebar__bg" onclick="togglenavSidebarDisplay()" />
      </div>
    </div>
  </nav>
</template>

<script>
import HeaderLoginMobile from './HeaderLoginMobile'
import { mapState } from 'vuex'

export default {
  name: 'HeaderNavSidebar',
  components: {
    HeaderLoginMobile,
  },
  computed: {
    ...mapState({
      navigationCategories: (state) => state.tag.navigationCategories,
      menuBrands: (state) => state.tag.menuBrands.slice(0, 10),
    }),
  },
}
</script>
