<template>
  <a :href="url" class="p-postCard__content__author">
    <div class="p-postCard__content__author__content">
      <span>{{ job }}</span>
      <span>{{ name }}</span>
    </div>
    <MediaImage :src="avatarUrl" :alt="'avatar'" :class="'p-postCard__content__authorImg'" />
  </a>
</template>

<script>
import MediaImage from '../../uiParts/images/MediaImage'

export default {
  components: { MediaImage },
  props: {
    url: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    job: {
      type: String,
      required: false,
      default: 'ライター',
    },
    avatarUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
