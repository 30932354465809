<template>
  <div class="p-spPickupContent">
    <a
      id="pr_top_pick_panel"
      :href="checkLink(post.data)"
      class="nav-link"
      data-method="get"
      :title="post.data.attributes.title"
      @click="clickItem"
    >
      <div
        v-if="isPost(post.data)"
        class="p-spPickupPostImg"
        :style="`background: url(${eyecatchUrl()}) no-repeat 0px; background-size: cover;`"
      ></div>
      <div
        v-else
        class="p-spPickupPostImg p-spPickupPostImgCart c-on"
        :style="`background: url(${fileUrl(post.included)}) no-repeat 0px; background-size: cover;`"
      ></div>
      <div class="p-spPickupPostItem">
        <TopPickDateIconContainer />
        <p v-if="isPost(post.data)">{{ post.data.attributes.title }}</p>
        <p v-else>{{ post.data.attributes.name }}</p>
      </div>
    </a>
  </div>
</template>

<script>
import TopPickDateIconContainer from '../../container/Top/TopPickDateIconContainer'
import noImage from '../../../../../assets/images/no_images/no-image.jpg'

export default {
  components: { TopPickDateIconContainer },
  props: {
    post: {
      type: Object,
      required: true,
    },
    withIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {}
  },
  methods: {
    clickItem() {
      sessionStorage.setItem('scroll_to', window.top.scrollY)
    },
    eyecatchUrl() {
      return this.post.data.attributes.eyecatchImage.url || noImage
    },
    isPost(postOrProduct) {
      return postOrProduct.attributes.published === true
    },
    fileUrl(productImages) {
      if (
        productImages !== undefined &&
        productImages[0] &&
        productImages[0].attributes.file &&
        productImages[0].attributes.file.url
      ) {
        return productImages[0].attributes.file.url
      }
      return noImage
    },
    checkLink(post) {
      if (this.isPost(post)) {
        return `/${post.id}`
      }
      return `${post.attributes.link}`
    },
  },
}
</script>
