<template>
  <div>
    <a :id="`pr_${id}`" class="p-postCard" :href="url" @click="clickItem">
      <div
        class="p-postCard__img p-postCard__imgCart"
        :class="{ on: hasProductPosts }"
        :style="`background: url(${eyecatchUrl}) no-repeat 0px;background-size: cover;`"
      />
      <div class="p-postCard__content">
        <div class="p-postCard__content__description">
          <p>{{ title }}</p>
        </div>
        <AvatarContainer v-if="author" :author="author" />
      </div>
    </a>
  </div>
</template>

<script>
import AvatarContainer from '../../container/PostCard/avatarContainer'

export default {
  components: { AvatarContainer },
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    eyecatchUrl: {
      type: String,
      default: '',
    },
    author: {
      type: Object,
      default: null,
      required: false,
      validator(obj) {
        return !!obj && 'name' in obj && 'slug' in obj
      },
    },
    hasProductPosts: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    clickItem() {
      if (this.id) {
        sessionStorage.setItem('scroll_to', window.top.scrollY)
      }
    },
  },
}
</script>
