<template>
  <MediaContentBLock>
    <MediaTitleBlock>
      <SectionHeadLabel :label="'New Post'" />
      <SectionHeadSubLabel :label="'新着記事'" />
    </MediaTitleBlock>

    <NewPostsContainer />
    <div class="l-mediaBlock__buttonBlock__wrap">
      <LinkButton :label="'新着記事一覧'" :url="newPostsUrl" />
    </div>
  </MediaContentBLock>
</template>

<script>
import SectionHeadSubLabel from '../uiParts/labels/SectionHeadSubLabel'
import SectionHeadLabel from '../uiParts/labels/SectionHeadLabel'
import MediaTitleBlock from '../uiParts/blocks/MediaTitleBlock'
import MediaContentBLock from '../uiParts/blocks/MediaContentBLock'
import LinkButton from '../uiParts/buttons/LinkButton'
import NewPostsContainer from '../container/Top/NewPostsContainer'

export default {
  components: {
    NewPostsContainer,
    MediaContentBLock,
    MediaTitleBlock,
    SectionHeadLabel,
    SectionHeadSubLabel,
    LinkButton,
  },
  data() {
    return {
      newPostsUrl: `/posts?page=1`,
    }
  },
}
</script>
