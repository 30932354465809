<template>
  <v-snackbar v-model="snackbar" :color="color" top>
    {{ text }}
    <v-btn text @click="closeMessage" dark> Close </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Message',
  computed: {
    snackbar: {
      get() {
        return this.$store.state.message.display
      },
      set() {
        return this.$store.dispatch({ type: 'message/destroyMessage' })
      },
    },
    color() {
      return this.$store.state.message.color
    },
    text() {
      return this.$store.state.message.text
    },
  },
  methods: {
    closeMessage() {
      this.$store.dispatch({ type: 'message/destroyMessage' })
    },
  },
}
</script>
