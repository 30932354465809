<template>
  <MediaContentBLock>
    <MediaTitleBlock>
      <SectionHeadLabel :label="'Pick Up'" />
      <SectionHeadSubLabel :label="'おすすめ記事'" />
    </MediaTitleBlock>
    <PickUpPostsContainer />
  </MediaContentBLock>
</template>

<script>
import SectionHeadSubLabel from '../uiParts/labels/SectionHeadSubLabel'
import SectionHeadLabel from '../uiParts/labels/SectionHeadLabel'
import PickUpPostsContainer from '../container/Top/PickUpPostsContainer'
import MediaTitleBlock from '../uiParts/blocks/MediaTitleBlock'
import MediaContentBLock from '../uiParts/blocks/MediaContentBLock'

export default {
  components: {
    MediaContentBLock,
    MediaTitleBlock,
    PickUpPostsContainer,
    SectionHeadLabel,
    SectionHeadSubLabel,
  },
}
</script>
