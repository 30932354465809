<template>
  <PanelListBLock v-if="features.length > 0">
    <PanelItemBlock v-for="(feature, index) in features" :key="index">
      <FeatureCard
        :id="index"
        :url="featureUrl(feature.attributes)"
        :title="feature.attributes.title"
        :slug="feature.attributes.slug"
        :cover-url="coverUrl(feature.attributes)"
      ></FeatureCard>
    </PanelItemBlock>
  </PanelListBLock>
</template>
<script>
import { mapState } from 'vuex'
import PanelListBLock from '../../uiParts/blocks/PanelListBLock'
import PanelItemBlock from '../../uiParts/blocks/PanelItemBlock'
import FeatureCard from '../../shared/FeatureCard'
import noImage from '../../../../../assets/images/no_images/no-image.jpg'

export default {
  components: {
    PanelListBLock,
    PanelItemBlock,
    FeatureCard,
  },
  computed: {
    ...mapState({
      features: (state) => state.top.features,
    }),
  },
  methods: {
    coverUrl(cover) {
      return cover.headerImage.url ? cover.headerImage.url : noImage
    },
    featureUrl(feature) {
      return `/features/${feature.slug}`
    },
  },
}
</script>
