<template>
  <MediaContentBLock>
    <MediaTitleBlock>
      <SectionHeadLabel :label="'Feature'" />
      <SectionHeadSubLabel :label="'特集'" />
    </MediaTitleBlock>

    <FeaturesContainer />
    <div class="l-mediaBlock__buttonBlock__wrap">
      <LinkButton :label="'過去の特集一覧'" :url="featuresUrl" />
    </div>
  </MediaContentBLock>
</template>

<script>
import SectionHeadSubLabel from '../uiParts/labels/SectionHeadSubLabel'
import SectionHeadLabel from '../uiParts/labels/SectionHeadLabel'
import MediaTitleBlock from '../uiParts/blocks/MediaTitleBlock'
import MediaContentBLock from '../uiParts/blocks/MediaContentBLock'
import LinkButton from '../uiParts/buttons/LinkButton'
import FeaturesContainer from '../container/Top/FeaturesContainer'

export default {
  components: {
    FeaturesContainer,
    MediaContentBLock,
    MediaTitleBlock,
    SectionHeadLabel,
    SectionHeadSubLabel,
    LinkButton,
  },
  data() {
    return {
      featuresUrl: `/features`,
    }
  },
}
</script>
