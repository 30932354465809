<template>
  <h2 class="l-mediaBlock__title">{{ label }}</h2>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
}
</script>
